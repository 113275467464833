
import Component, {mixins} from 'vue-class-component';

import Plan from '@/mixins/Plan';
import Recordings from '@/mixins/Recordings';

import Gotcha from '@/components/Gotcha.vue';
import ConfirmDeleteRecording from '@/components/ConfirmDeleteRecording.vue';
import AccountRecordings from '@/components_v2/intelligent/personal/__COMP_AccountRecordings.vue';
import Transcripts from '@/components_v2/intelligent/personal/__COMP_Transcripts.vue';
import AudioPlayer from '@/components_v2/intelligent/video/__COMP_AudioPlayer.vue';
import IconPlusPrimary from '@/components/icons/IconPlusPrimary.vue';

import {meetingStore} from '@/store/__STORE_meetings';
import {mainStore} from '@/store/__STORE_main';
import {userStore} from '@/store/__STORE_user';
import CreateFolderDialog from '@/components/CreateFolderDialog.vue';
import MoveFolderDialog from '@/components/MoveFolderDialog.vue';
import EditFileDialog from '@/components/EditFileDialog.vue';

@Component({
  components: {
    Gotcha,
    ConfirmDeleteRecording,
    AudioPlayer,
    IconPlusPrimary,
    AccountRecordings,
    Transcripts,
    CreateFolderDialog,
    EditFileDialog,
    MoveFolderDialog,
  },
})
/**
 *
 */
export default class RecordingsTranscripts extends mixins(Plan, Recordings) {
  name: 'Recordings and Transcripts';

  tipList = [
    'You will find your recorded meetings here',
    'You can choose storage plans that will persist recordings for 24 hours or forever',
  ];
  isShowGotcha = true;
  stopMadHackz = false;
  currentPath= [];

  /**
   * @return {void}
   */
  mounted(): void {
    mainStore.setLoading();
    meetingStore.getStorage().then(() => {
      meetingStore.getRecordings();
      meetingStore.getTranscripts();
      mainStore.clearStatus();
    });
  }
  /**
   * computed
   */
  get displayedBreadcrumbs() {
    const MAX_BREADCRUMB_LENGTH = 5;
    if (this.currentPath.length <= MAX_BREADCRUMB_LENGTH) {
      return this.currentPath;
    } else {
      const displayed = this.currentPath.slice(this.currentPath.length - MAX_BREADCRUMB_LENGTH);
      return ['...', ...displayed];
    }
  }

/**
   * navigate to breadcurmbs
   */
    navigateToBreadcurmbs(item) {
      const childComponent = this.$refs.childRef as AccountRecordings | undefined;
    if (childComponent) {
      childComponent.navigateToFolder(item);
    }
  }
/**
   * current  breadcurmbs path
   */
  handleCurrentPathEvent(currentPath) {
    // Receive the currentPath emitted from the child component
    this.currentPath = currentPath;
  }

  /**
   * delete a recording
   * @return {Promise<String>}
   */
  async deleteRecording(): Promise<String> {
    if (this.stopMadHackz) {
      return 'plz stop';
    }
    this.stopMadHackz = true;
    if (this.selectedDeleteFolderRecording.status === 'folder') {
      const payload = {
        folder_id: this.selectedDeleteFolderRecording.id,
        user_id: this.userInfo.id,
      };
      await meetingStore.deleteFolderRecording(payload)
          .then(() => {
            this.$toast.success('Deleted successfully!');
          })
          .catch((err) => {
            this.$toast.error(err);
          });
    } else {
      await meetingStore.deleteRecording(this.selectedRecordingId)
          .then(() => {
            this.$toast.success('Deleted successfully!');
          })
          .catch((err) => {
            this.$toast.error(err);
          });
    }
    this.closeConfirmDeleteRecording();
    this.stopMadHackz = false;
  }

  /**
   * delete a recording
   * @return {Promise<String>}
   */
  async deleteTranscript(): Promise<String> {
    if (this.stopMadHackz) {
      return 'plz stop';
    }
    this.stopMadHackz = true;
    await meetingStore.deleteTranscript(this.selectedTranscriptId)
        .then(() => {
          this.$toast.success('Deleted successfully!');
        })
        .catch((err) => {
          this.$toast.error(err);
        });
    this.closeConfirmDeleteTranscript();
    this.stopMadHackz = false;
  }

  /**
   * Close the Player
   * @return {void}
   */
  closePlayer(): void {
    meetingStore.closeAudioPlayer();
  }

  /**
   * close the confirm delete recordings modal and clear state
   * @return {void}
   */
  closeConfirmDeleteRecording(): void {
    const clearMe = {
      value: false,
      id: null,
      status: '',
    };
    meetingStore.setShowDeleteRecording(clearMe);
  }

  /**
   * close the confirm delete transcript modal and clear state
   * @return {void}
   */
  closeConfirmDeleteTranscript(): void {
    const clearMe = {
      value: false,
      id: '',
    };
    meetingStore.setShowDeleteTranscript(clearMe);
  }

   /**
   * close create folder
   * @return {void}
   */
  closeFolderPopup(): void {
    const clearMe = {
      value: false,
      id: null,
      name: '',
      status: '',
    };
    meetingStore.setShowCloseFolder(clearMe);
  }

    /**
   * edit File Recording
   * @return {void}
   */
  editFileRecordingPopup(): void {
    const editMe = {
      value: false,
      id: null,
      name: ''
    };
    meetingStore.setShowEditFileRecording(editMe);
  }

    /**
   * close Move folder
   * @return {void}
   */
  closeMoveFolderPopup(): void {
    const moveMe = {
      value: false,
      id: null,
      status: ''
    };
    meetingStore.setShowMoveFolder(moveMe);
  }

   /**
   * Get userInfo
   */
  get userInfo() {
    return userStore.__GETTERuserProfile;
  }

  /**
   * Get state of audio player
   * @return {boolean}
   */
  get isShowAudioPlay(): boolean {
    return this.AudioPlayer.isShowAudioPlayer;
  }

  /**
   * Get state of show Delete Recording
   * @return {boolean}
   */
  get isShowConfirmDeleteRecording(): boolean {
    return meetingStore.__GETTERshowDeleteRecording.state;
  }

  /**
   * Get state of show Delete Transcript
   * @return {boolean}
   */
  get isShowConfirmDeleteTranscript(): boolean {
    return meetingStore.__GETTERshowDeleteTranscript;
  }

  /**
   * Get selected recording id
   * @return {any}
   */
  get selectedRecordingId(): any {
    return meetingStore.__GETTERdeleteRecordingId;
  }

  /**
   * Get selected transcript id
   * @return {string}
   */
  get selectedTranscriptId(): string {
    return meetingStore.__GETTERdeleteTranscriptId;
  }

    /**
   * Get selected delete folder
   * @return {any}
   */
  get selectedDeleteFolderRecording(): any {
    return meetingStore.__GETTERshowDeleteRecording;
  }

    /**
   * Get state of show Edit file Recordings
   * @return {boolean}
   */
  get isShowConfirmEditFileRecording(): boolean {
    return meetingStore.__GETTERshowEditFile;
  }

    /**
   * Get state of show Create Folder
   * @return {boolean}
   */
  get isShowConfirmCloseFolder(): boolean {
    return meetingStore.__GETTERshowCloseFolder.state;
  }

    /**
   * Get selected edit folder Name
   * @return {any}
   */
  get selectedEditFolderRecordingName(): any {
    return meetingStore.__GETTERshowCloseFolder.folderName;
  }

   /**
   * Get selected edit folder Id
   * @return {any}
   */
  get selectedEditFolderRecordingId(): any {
    return meetingStore.__GETTERshowCloseFolder.id;
  }

   /**
   * Get selected edit folder Status
   * @return {any}
   */
  get selectedEditFolderRecordingStaus(): any {
    return meetingStore.__GETTERshowCloseFolder.status;
  }

   /**
   * Get state of show Move Folder
   * @return {boolean}
   */
  get isShowConfirmCloseMoveFolder(): boolean {
    return meetingStore.__GETTERshowCloseMoveFolder.state;
  }

    /**
   * Get selected edit file Name
   * @return {any}
   */
  get selectedEditFileRecordingName(): any {
    return meetingStore.__GETTERshowEditFileName.fileRecordingName;
  }

  /**
   * Get the audio player for this recording
   * @return {{
    selectedRecording: any,
    recordingName: string,
    isShowAudioPlayer: boolean,
  }} - Audio Player object where the selected Recording is passed in, the recording Name, and a boolean to show the Player or not.
   */
  get AudioPlayer(): {
    selectedRecording: any,
    recordingName: string,
    isShowAudioPlayer: boolean,
    } {
    return meetingStore.__GETTERaudioPlayer;
  }
}
