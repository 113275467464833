
import Component, {mixins} from 'vue-class-component';

import Plan from '@/mixins/Plan';

import {meetingStore} from '@/store/__STORE_meetings';

@Component
/**
 *
 */
export default class Recordings extends mixins(Plan) {
  name: 'Storage State'

  /**
   * get recordings storage plan
   */
  get accountStorage() {
    return meetingStore.storage;
  }

  /**
   * get if user has active storage add-on
   */
  get activeStorageAddon() {
    return this.__MIXINhasActiveStorageAddon;
  }

  /**
   * get total plan storage capacity
   */
  get totalStorageGb() {
    const bytes = this.accountStorage ? this.accountStorage.total_bytes : 0;
    return (bytes / 1000000000).toFixed(2);
  }

  /**
   * get if user has free Storage Plan
   */
  get freeStorageGb() {
    const bytes = this.accountStorage ? this.accountStorage.free_bytes : 0;
    return (bytes / 1000000000).toFixed(2);
  }

  /**
   * get if user has Fixed Storage Plan
   */
  get hasFixedGbsPlan() {
    return this.accountStorage ? this.accountStorage.has_fixed_gbs_plan : null;
  }
}
