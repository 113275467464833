
import Component, {mixins} from 'vue-class-component';
import {Watch} from 'vue-property-decorator';

import IconDownload from '@/components/icons/IconDownload.vue';
import DateFormat from '@/mixins/DateFormat';

import {meetingStore} from '@/store/__STORE_meetings';


@Component({
  components: {
    IconDownload,
  },
})
/**
 * Intelligent component to play the video and audio of a recording
 */
export default class AudioPlayer extends mixins(DateFormat) {
  name: 'Audio Player';

  private isMinimise = false;

  /**
   * Watches the selectedRecording to see if it changes
   * @return {void}
   */
  @Watch('selectedRecording', {deep: true, immediate: true})
  watchRecording(): void {
    this.isMinimise = false;
  }

  /**
   * Gets the audioPlayer state from the store
   * @return {any} - audio player state
   */
  get audioPlayerState(): any {
    return meetingStore.__GETTERaudioPlayer;
  }

  /**
   * Gets the selected recording object from the store state
   * @return {any} - recording object
   */
  get selectedRecording(): any {
    return this.audioPlayerState.selectedRecording;
  }

  /**
   * Gets the selected recordings name from the store state
   * @return {string}
   */
  get selectedRecordingName(): string {
    return this.audioPlayerState.recordingName;
  }

  /**
   * Gets the current value of the minimised state
   * @return {boolean}
   */
  get getIsMinimised(): boolean {
    return this.isMinimise;
  }

  /**
   * Toggles whether the media player is minimised or not
   * @return {void}
   */
  toggleMinimise(): void {
    this.isMinimise = !this.isMinimise;
  }

  /**
   * Calls the store Action to close the media player
   * @return {void}
   */
  close(): void {
    meetingStore.closeAudioPlayer();
  }
}
