<template>
  <div
    class="media-player_container"
    :class="{'minimize': isMinimize}"
  >
    <div class="media-player_header">
      <span class="title">Player</span>
      <a
        href="#"
        class="fas fa-times"
        @click="$emit('close')"
      />
      <a
        :href="record.mp4"
        target="_blank"
        class="fas fa-external-link-alt"
      />
      <a
        href="#"
        class="far fa-window-minimize"
        @click.prevent="isMinimize = !isMinimize"
      />
    </div>
    <div class="media-player_content">
      <div class="row">
        <div class="col-12">
          <div class="music-icon">
            <i class="fas fa-music" />
          </div>
          <div class="media-information">
            <p>{{ recordName }}</p>
            <span><i class="far fa-calendar-alt" />{{ record.created_at | moment('DD/MM/YYYY HH:mm') }}</span>
            <!--
            <span><i class="fab fa-mixcloud"></i>{{ data_usage }}%</span>
            -->
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0">
      <div
        v-if="record.mp4"
        class="col-12"
      >
        <video
          width="100%"
          :src="record.mp4"
          type="video/mp4"
          autoplay
          controls
          :style="{
            pointerEvents: 'auto',
          }"
        />
        <div class="download-action">
          <a
            :href="record.mp4"
            download
            target="_blank"
          >
            <icon-download
              :height="16"
              :width="16"
            />
            Download
          </a>
        </div>
      </div>
      <div
        v-else-if="record.mp3"
        class="col-12"
      >
        <audio controls>
          <source
            :src="record.mp3"
            type="audio/mpeg"
          >
        </audio>
        <div class="download-action">
          <a
            :href="record.mp3"
            download
            target="_blank"
          >
            <icon-download
              :height="16"
              :width="16"
            />
            Download
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconDownload from '@/components/icons/IconDownload.vue';
export default {
  components: {
    IconDownload,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
    recordName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isMinimize: false,
    };
  },
  watch: {
    record: function() {
      if (this.isMinimize) {
        this.isMinimize = false;
      }
    },
  },
};
</script>
