
import Component, {mixins} from 'vue-class-component';
import {Watch} from 'vue-property-decorator';
import Gotcha from '@/components/Gotcha.vue';
import ConfirmDeleteRecording from '@/components/ConfirmDeleteRecording.vue';
import IconPlusPrimary from '@/components/icons/IconPlusPrimary.vue';
import IconDownload from '@/components/icons/IconDownload.vue';
import StorageState from '@/components_v2/intelligent/personal/__COMP_StorageState.vue';

import Plan from '@/mixins/Plan';
import DateFormat from '@/mixins/DateFormat';

import {meetingStore} from '@/store/__STORE_meetings';
import {mainStore} from '@/store/__STORE_main';

@Component({
  components: {
    Gotcha,
    ConfirmDeleteRecording,
    IconPlusPrimary,
    IconDownload,
    StorageState,
  },
})
/**
 *
 */
export default class Transcripts extends mixins(Plan, DateFormat) {
  name: 'Recordings and Transcripts';

  tipList = [
    'You will find your recorded meetings here',
    'You can choose storage plans that will persist recordings for 24 hours or forever',
  ];
  isShowGotcha = true;
  isShowConfirmDeleteTranscript = false;
  storageFetched = false;
  selRecord = {};
  curContent = 'This recording will be deleted immediately. You can’t undo this action.';
  curPage = '';
  stopMadHackz = false;

  /**
   * Happens on mount
   * @return {void}
   */
  mounted(): void {
    mainStore.setLoading();
    meetingStore.getStorage().then(() => {
      this.storageFetched = true;
      meetingStore.getTranscripts();
      mainStore.clearStatus();
    });
  }

  /**
   * recall init table if update
   * @return {void}
   */
  updated(): void {
    this.initTable();
  }

  /**
   * Watches for if the current page of recordings
   * @param {any} val - the recordings list
   * @return {void}
   */
  @Watch('transcriptList')
  updateList(val: any): void {
    this.curPage = val.current_page;
  }

  /**
   * @param {any} transcript
   * @return {string}
   */
  availableTill(transcript: any): string {
    if (transcript.will_be_deleted_at !== null) {
      return this.__MIXINformatDateTime(transcript.will_be_deleted_at);
    }
    return '';
  }

  /**
   * Confirm deletion of current Record
   * @param {any} curTranscript - current selected recording to be deleted
   * @return {void}
   */
  confirmDelete(curTranscript): void {
    const deleteMe = {
      value: true,
      id: curTranscript.id,
    };
    meetingStore.setShowDeleteTranscript(deleteMe);
  }

  /**
   * Initialize the table of recordings
   * @return {void}
   */
  initTable(): void {
    const element = document.querySelectorAll('.tr-has-expandable');

    for (let i = 0; i < element.length; i++) {
      const elem = element[i] as any;
      elem.onclick = function(e: any) {
        const that = this;
        if (!that.classList.contains('is-expanded')) {
          that.querySelector('.td-expand').children[0].classList.remove('fa-chevron-down');
          that.querySelector('.td-expand').children[0].classList.add('fa-chevron-up');
          that.classList.add('is-expanded');
          that.nextElementSibling.children[0].children[0].style.maxHeight = that.nextElementSibling.children[0].children[0].scrollHeight + 'px';
        } else {
          that.querySelector('.td-expand').children[0].classList.remove('fa-chevron-up');
          that.querySelector('.td-expand').children[0].classList.add('fa-chevron-down');
          that.classList.remove('is-expanded');
          that.nextElementSibling.children[0].children[0].style.maxHeight = '';
        }
      };
    }
  }

  /**
   * Calculate the total amount of space currently used
   * @param {any} curTranscript
   * @return {string}
   */
  calcUsage(curTranscript): string {
    let storage = 'pdf ' + this.bytesToMb(curTranscript.pdf.file_size) + 'MB';

    if (curTranscript.zip.file_size) {
      storage += ', zip ' + this.bytesToMb(curTranscript.zip.file_size) + 'MB';
    }

    return storage;
  }

  /**
   * bytes to Megabytes
   * @param {number} bytes
   * @return {string}
   */
  bytesToMb(bytes: number): string {
    return parseFloat(String(bytes / 1000000)).toFixed(2);
  }

  /**
   * delete a recording
   * @return {Promise<String>}
   */
  async deleteTranscript(): Promise<String> {
    if (this.stopMadHackz) {
      return 'plz stop';
    }
    this.stopMadHackz = true;
    await meetingStore.deleteTranscript(this.selRecord['id'])
        .then(() => {
          this.$toast.success('Deleted successfully!');
        })
        .catch((err) => {
          this.$toast.error(err);
        });
    this.isShowConfirmDeleteTranscript = false;
    this.stopMadHackz = false;
  }

  /**
   * get specific page number from recordings
   * @param {number} pageNum
   * @return {void}
   */
  go(pageNum): void {
    meetingStore.getTranscripts(pageNum);
  }

  /**
   * @param {any} transcript
   * @return {void}
   */
  downloadPdf(transcript: any): void {
    mainStore.setLoading();
    meetingStore.getPdf(transcript.id)
        .then((resp) => {
          const data = resp.data;
          const download = data.pdf_url;
          const link = document.createElement('a') as any;
          link.download = 'transcript.pdf';
          link.href = download;
          link.target = '_blank';
          link.click();
          link.remove();
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error('Something went wrong downloading pdf.');
        });
    mainStore.clearStatus();
  }

  /**
   * @param {any} transcript
   * @return {void}
   */
  downloadZip(transcript: any): void {
    mainStore.setLoading();
    meetingStore.getZip(transcript.id)
        .then((resp) => {
          const data = resp.data;
          const download = data.zip_url;
          const link = document.createElement('a') as any;
          link.download = 'transcript.zip';
          link.href = download;
          link.target = '_blank';
          link.click();
          link.remove();
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error('Something went wrong downloading zip.');
        });
    mainStore.clearStatus();
  }

  /**
   * get list of recordings
   * @return {any}
   */
  get transcriptList(): any {
    return meetingStore.transcripts;
  }

  /**
   * get recordings storage plan
   * @return {any}
   */
  get accountStorage(): any {
    return meetingStore.storage;
  }

  /**
   * get if user has active storage add-on
   * @return {Boolean}
   */
  get activeStorageAddon(): Boolean {
    return this.__MIXINhasActiveStorageAddon;
  }
}
