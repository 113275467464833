<template>
  <div
    id="modal-calendar-integration"
    class="f-modal"
  >
    <div class="f-modal-table">
      <div class="f-modal-cell align-middle">
        <div class="f-modal-content">
          <div
            class="f-modal-close"
            @click="$emit('close')"
          />
          <div class="modal-popup">
            <div class="modal-popup-content">
              <!-- <confirm-dialog-icon /> -->

              <h6 class="mb-2">
                {{ editStatus ? 'Rename Folder' : ' New Folder' }}
              </h6>

              <div
                class="form-group mt-1 mb-2"
                :class="{'form-error': errors.folder}"
              >
                <input
                  id="folder-name"
                  v-model="folder"
                  class="form-control modal-input"
                  type="text"
                  placeholder="Enter Folder Name"
                >
                <div
                  v-for="(error, index) in errors.folder"
                  :key="index"
                  class="form-error-message"
                >
                  {{ error }}
                </div>
              </div>
            </div>

            <div class="text-center">
              <a
                href="#"
                class="btn btn-outline"
                @click.prevent="$emit('close')"
              >Cancel</a>
              <a
                href="#"
                class="btn btn-primary ml-2"
                @click.prevent="createFolder"
              >
                {{ editStatus ? 'Rename' : 'Create' }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import ConfirmDialogIcon from './ConfirmDialogIcon';

import {mainStore} from '@/store/__STORE_main';
import {meetingStore} from '@/store/__STORE_meetings';
import {userStore} from '@/store/__STORE_user';

export default {
//   components: {
//     ConfirmDialogIcon,
//   },
  props: {
    // editFolderId: {
    //   type: Number,
    //   default: null,
    // },
    editFolder: {
      type: String,
      default: null,
    },
    editStatus: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      folder: this.editFolder,
      errors: {},
    };
  },
  computed: {
    userInfo() {
      return userStore.__GETTERuserProfile;
    },
    editFolderRecordingId() {
      return meetingStore.__GETTERshowCloseFolder;
    },
  },
  created() {
    // eslint-disable-next-line
        $('body').addClass('f-modal-is-open');
  },
  destroyed() {
    // eslint-disable-next-line
        $('body').removeClass('f-modal-is-open');
  },
  methods: {
    createFolder() {
      this.errors = {};

      const regex = /^[a-zA-Z0-9\s]+$/; // Regex pattern for only numbers and text
      if (!this.folder.length) {
        this.errors = {folder: ['The folder name is required']};
        return;
      } else if (!regex.test(this.folder)) {
        this.errors = {folder: ['The folder name should contain only numbers and text']};
        return;
      }

      mainStore.setLoading();
      if (this.editStatus === 'edit') {
        const payload = {
          folder_name: this.folder,
          folder_id: this.editFolderRecordingId.id,
          user_id: this.userInfo.id,
        };
        meetingStore.renameFolder(payload)
            .then(() => {
              this.folder = '';
              this.$toast.success('Folder raname successfully!');
              this.$emit('close');
              // window.location.reload();
            })
            .catch((err) => {
              this.$toast.error(err.response.data.message);
              if (err.response.status === 422) {
                if (err.response.data.errors) {
                  this.errors = err.response.data.errors;
                } else {
                  this.errors = [];
                }
              }
            });
      } else {
        const payload = {
          folder_name: this.folder,
          folder_parent_id: this.editFolderRecordingId.id ? this.editFolderRecordingId.id : '',
          user_id: this.userInfo.id,
        };
        meetingStore.createFolder(payload)
            .then(() => {
              this.folder = '';
              this.$toast.success('Folder created successfully!');
              this.$emit('close');
            // window.location.reload();
            })
            .catch((err) => {
              this.$toast.error(err.response.data.message);
              if (err.response.status === 422) {
                if (err.response.data.errors) {
                  this.errors = err.response.data.errors;
                } else {
                  this.errors = [];
                }
              }
            });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-input {
  font-size: 1.5rem;
}
</style>
