import Vue from 'vue';
import Component from 'vue-class-component';

@Component
/**
 * Plan Mixin for methods to return user plan permissions
 */
export default class Recordings extends Vue {
  /**
   * Mixin to get our specific recording Name
   * @param {any} recording
   * @return {(string|null)}
   */
  __MIXINrecordingName(recording: any): (string|null) {
    if (recording.meeting) {
      return recording.meeting.subject;
    }

    if (recording.meeting_room) {
      return 'Room ' + recording.meeting_room.webrtc.alias;
    }

    return '';
  }
}
