import Vue from 'vue';
import Component from 'vue-class-component';

import {userStore} from '@/store/__STORE_user';
import {meetingStore} from '@/store/__STORE_meetings';

@Component
/**
 * Plan Mixin for methods to return user plan permissions
 */
export default class Plan extends Vue {
  /**
   * Gets profile dta from the userStore
   */
  get __MIXINauthUser() {
    return userStore.profileData;
  }

  /**
   * Returns whether the account has admin management rights
   */
  get __MIXINaccountHasAdminManagement(): Boolean {
    return (this.__MIXINauthUser && this.__MIXINauthUser.account) ? this.__MIXINauthUser.account.has_admin_management : false;
  }

  /**
   * Returns whether the account is on a paid plan
   */
  get __MIXINisPaidPlan() : Boolean {
    return this.__MIXINauthUser && this.__MIXINauthUser.billing_plan && (this.__MIXINauthUser.billing_plan.is_paid);
  }

  /**
   * Returns whether the account can NOT access admin management rights
   */
  get __MIXINauthUserLoadedAndCannotAccessAdminFeatures(): Boolean {
    return this.__MIXINauthUser && this.__MIXINauthUser.role && !this.__MIXINcanAuthUserAccessAdminFeatures;
  }

  /**
   * Returns whether the account can access admin management rights
   */
  get __MIXINcanAuthUserAccessAdminFeatures(): Boolean {
    // Business plan has admin management. Some sections/features can only be accessed by admins.
    if (this.__MIXINaccountHasAdminManagement) {
      return this.__MIXINauthUser && this.__MIXINauthUser.role === 'Admin';
    }

    if (this.__MIXINauthUser && this.__MIXINauthUser.is_provisioned) {
      return this.__MIXINauthUser.role === 'Admin';
    }

    if ( this.__MIXINisAuthUserOnFreeTrialLaunchPlan) {
      return true;
    }

    return false;
  }
  /**
   * Returns whether the account can edit business branding
   */
  get __MIXINcanEditBranding() {
    const billingPlan = this.__MIXINauthUser && this.__MIXINauthUser.billing_plan;
    if (!billingPlan) {
      return false;
    }

    // Anyone on SAAS Trial can edit branding
    if (!this.__MIXINauthUser.is_provisioned && billingPlan.name === 'Trial') {
      return true;
    }

    // For other plans (including PP), only Admins can edit Business branding:
    return (this.__MIXINauthUser.role === 'Admin') && billingPlan.has_admin_management;
  }

  /**
   * Returns whether the account can edit personal branding
   */
  get __MIXINcanEditPersonalBranding(): Boolean {
    const billingPlan = this.__MIXINauthUser && this.__MIXINauthUser.billing_plan;
    if (!billingPlan) {
      return false;
    }

    const except = ['No License'];
    return !except.includes(billingPlan.name);
  }

  /**
   * Returns whether the account can edit the room alias
   */
  get __MIXINcanEditAlias(): Boolean {
    const billingPlan = this.__MIXINauthUser && this.__MIXINauthUser.billing_plan;
    if (!billingPlan) {
      return false;
    }

    const except = ['Free', 'No License', 'Launch'];
    return !except.includes(billingPlan.name);
  }

  /**
   * Returns whether the account is on a free , trial or launch plan
   */
  get __MIXINisAuthUserOnFreeTrialLaunchPlan(): Boolean {
    // Allow free, trial, launch users to access admin features as a regular user
    const allowedAccounts = ['Free', 'Trial', 'Launch'];
    const accountBillingPlan = (this.__MIXINauthUser && this.__MIXINauthUser.account) ? this.__MIXINauthUser.account.billing_plan : '';
    return allowedAccounts.includes(accountBillingPlan.name);
  }

  /**
   * Returns whether the account is on a trial plan
   */
  get __MIXINisAuthUserOnTrialPlan(): Boolean {
    return this.__MIXINauthUser && this.__MIXINauthUser.billing_plan && this.__MIXINauthUser.billing_plan.name === 'Trial';
  }

  /**
   * Returns whether the account can access the Operations Dashboard
   */
  get __MIXINcanAuthUserAccessOpsDash(): Boolean {
    if ( this.__MIXINisAuthUserOnTrialPlan) {
      return true;
    }
    const billingPlan = this.__MIXINauthUser && this.__MIXINauthUser.billing_plan;
    if (!billingPlan) {
      return false;
    }
    // For other plans (including PP), only Admins can access the Ops Dash:
    return (this.__MIXINauthUser.role === 'Admin') && billingPlan.has_admin_management;
  }

  /**
   * Returns whether the account can access the Hail Mary feature
   */
  get __MIXINuserCanAccessHailMary(): Boolean {
    if (this.__MIXINauthUser && this.__MIXINauthUser.billing_plan && this.__MIXINauthUser.billing_plan.name === 'Trial') {
      return true;
    }
    if (this.__MIXINauthUser && this.__MIXINauthUser.addons && this.__MIXINauthUser.addons.some( (e) => e.name === 'XR Gateway')) {
      return true;
    }
    if (this.__MIXINauthUser && this.__MIXINauthUser.billing_plan && this.__MIXINauthUser.billing_plan.included_addons.some( (e) => e.name === 'XR Gateway')) {
      return true;
    }
    return false;
  }

  /**
   * Returns whether the account is created through the provisioning portal
   */
  get __MIXINisUserProvisioned(): Boolean {
    if (this.__MIXINauthUser) {
      return this.__MIXINauthUser.is_provisioned;
    }
    return false;
  }

  /**
   * Returns whether the account a provisioned account and not yet assigned a license
   */
  get __MIXINisUserOnNoLicense(): Boolean {
    return this.__MIXINauthUser && this.__MIXINauthUser.is_provisioned && this.__MIXINauthUser.billing_plan === null;
  }

  /**
   * Returns whether the user has an active storage addon or not
   */
  get __MIXINhasActiveStorageAddon(): Boolean {
    return this.__MIXINauthUser && meetingStore.storage.active_storage_addon;
  }

  /**
   * Calculates the orientation of the device.
   * @param {any} level
   * @return {boolean} whether the user's device is landscape
   */
  __MIXINisPlanLevelAtLeast(level): Boolean {
    if (this.__MIXINauthUser.is_provisioned) {
      return true;
    }
    return this.__MIXINauthUser && this.__MIXINauthUser.billing_plan && (this.__MIXINauthUser.billing_plan.level >= level);
  }
}
